.top-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #1e1e2d;
    /* height: 32px; */
    height: 3rem;

    align-items: center;
    padding: 4px;
}

.top-nav button {
    background: none;
    border: none;
}

.top-nav svg {
    width: 2em;
    height: 2em;
    color: grey;
}

.top-nav a {
    text-decoration: none;
    color: black;
}

.logo {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 50px;
    height: 50px;
} 

.profile {
    display: flex;
    flex-flow: row nowrap;
    padding-right: 2%;
}

.profile > div {
    margin: 4px;
}

.profile img {
    width: 30px;
    height: 30px;
}

.profile .name {
    color: grey;
    font-size: 1em;
}

.menu-sub {
    position: fixed;
    top: 40px;
    border-radius: 5px;
    /* width: 100px; */
    transform: translateX(-75%);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    background-color: white;
    z-index: 100;
}

.menu-sub .info {
    word-wrap: break-word;
    color: grey;
    font-size: 14px;
}

.menu-sub > div {
    border-bottom: 1px solid rgba(199, 198, 198, 30%);
    margin: 6px 0;
    padding: 6px 0;
    color: black;
}

.menu-sub > div:last-child {
    border: none;
}

.menu-sub > div > div {
    margin: 4px 0;
}

@media (max-width: 425px) {
    .menu-sub {
        transform: translateX(-35%);
        width: 100px;
    }
}

@media (min-width: 768px) {
    .menu-toggle button{
        display: none;
    }
    .menu-sub {
        max-width: 200px;
    }
}