.mobile-nav {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%
}

.mobile-nav .nav {
    display: block;
    width: 150px;
}

.mobile-nav .overlay{
    flex: 2 1 auto;
    background-color: rgb(214, 214, 214);
    opacity: 30%;
}

.hidden .nav {
    display: none;
  }

.nav {
    display: flex;
    flex-flow: column;
    padding: 10px;
    background-color: #1e1e2d;
    color: white;
    height: 100vh;
    transition: all 0.5s ease-in-out;
    flex: auto;
  }
  
  
  .nav > div {
    margin: 4px;
    padding: 2px;
  }
  
  .nav > div svg {
    width: 24px;
    height: 24px;
  }
  
  .nav a {
    color: white;
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  
  .nav span {
    margin-left: 8px;
  }
  
  .toggle-button {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
  .toggle-button button {
    background: none;
    border: none;
  }
  .toggle-button button svg {
    width: 20px;
    height: 20px;
    color: grey;
  }
  
  