.wrapper {
    margin: 1em;
    max-width: 1080px;
}

.title {
    margin: 10px;
}

.wrapper div select {
    margin: 10px 0;
    font-size: 11px;
    max-width: 475px;

}

.wrapper form > div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

