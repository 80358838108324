.app {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
}

.app > div {
  display: flex;
}

.app .body-container {
  flex: 2 1 auto;
  display: flex;
  flex-flow: column;
}

.center {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}
