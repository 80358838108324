.nav {
  display: flex;
  flex-flow: column;
  padding: 10px;
  background-color: #1e1e2d;
  color: white;
  height: 100vh;
  transition: all 0.5s ease-in-out;
}

.expanding {
  flex: auto;
}

.min {
  width: 60px;
  align-items: center;
}

.nav > div {
  margin: 4px;
  padding: 2px;
}

.nav > div svg {
  width: 24px;
  height: 24px;
}

.nav a {
  color: white;
  text-decoration: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.nav span {
  margin-left: 8px;
}

.logo {
  border-bottom: 1px solid rgba(95, 95, 95, 0.74);
}

.logo p {
  font-size: 20px;
}

.toggle-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
.toggle-button button {
  background: none;
  border: none;
}
.toggle-button button svg {
  width: 20px;
  height: 20px;
  color: grey;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
}
