.body-container {
  flex: 2 1 auto;
  display: flex;
  flex-flow: column;
  overflow: scroll;
}

.center {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}
