.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.wrapper {
  width: 300px;
  padding: 1em;
  border-radius: 8px;
  border: 2px solid #0e540c;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.wrapper input {
  outline: grey;
}

.input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  /* justify-content: center; */
  align-items: center;
}

.input-wrapper .disable {
  border: none;
}

.label {
  font-weight: 600;
}

.btn-panel {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.btn-panel button {
  margin: 2px 8px;
  border-radius: 4px;
  background-color: #ffd200;
  border: none;
  cursor: pointer;
}

.btn-panel button.disable {
  display: none;
}

.pass-form {
  margin-top: 1em;
}

.primary-btn {
  border: none;
  background-color: #0094ff;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
}

.hidden {
  display: none;
}

.not {
  color: orangered;
}

.avatar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid grey;
  width: 180px;
  margin: auto;
}

.ava-upload {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ava-upload input,
.ava-upload button {
  margin: 4px;
}

.ava-upload button {
  background-color: #0094ff;
  font-size: 11px;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
}

.notDiv {
  margin: 5px;
  display: flex;
  justify-content: center;
}
