.wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.success {
    color: green;
}

.err {
    color: red;
}

